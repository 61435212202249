import http from "../utils/http";
import { config } from "../utils/config";
export function getGasPrice() {
  return http.post(config.api + "/api/index/getGasPrice");
}
export function getHashrate() {
  return http.post(config.api + "/api/index/getHashrate");
}
export function getBlockNumber() {
  return http.post(config.api + "/api/index/getBlockNumber");
}
export function getBlockList() {
  return http.post(config.api + "/api/index/getBlockList");
}
export function getBlockInfo(params) {
  return http.post(config.api + "/api/block/blockInfo", params);
}
export function getBlockAllList(params) {
  return http.post(config.api + "/api/block/blockList", params);
}
export function getTransactionAllList(params) {
  return http.post(config.api + "/api/transaction/transactionList", params);
}
export function getTransactionInfo(params) {
  return http.post(config.api + "/api/transaction/transactionInfo", params);
}
export function getBlance(params) {
  return http.post(config.api + "/api/address/balance", params);
}
export function getTransactionByAddress(params) {
  return http.post(config.api + "/api/address/transactionList", params);
}
export function getTranByAddress(params) {
  return http.post(
    config.api + "/api/transaction/getTokenTransactionList",
    params
  );
  // return http.post(
  //   "http://192.168.1.37:3000" + "/api/transaction/getTokenTransactionList",
  //   params
  // );
}

export function getTokenAllList(params) {
  return http.post(config.api + "/api/token/tokenList", params);
}
export function getPriceApi() {
  return http.get(config.jApi + "/index.php/Api/v1/publicapi/getTaxAndPrice");
}
